import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

const ClientsWrapper = styled.div`
    text-align: center;
    margin: 80px auto;
`

const Grid = styled.div`
    margin: 0 auto;
    max-width: 1080px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media screen and (min-width: 780px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
`



const FilmClients = () => {

   const images = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "filmClients" }
          ext: { regex: "/(jpeg|jpg|gif|png)/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              id
              gatsbyImageData
            }
            name
          }
        }
      }
    }
  `)

  const imageData = images.allFile.edges

    return (
        <ClientsWrapper>
            <h4>Clients</h4>
            <Grid>
                {imageData.map((image) => {

                    return (
                    <GatsbyImage
                        key={image.node.childImageSharp.id}
                        image={image.node.childImageSharp.gatsbyImageData}
                        alt={image.node.name}
                        placeholder="blurred"
                        layout="fluid"
                    />
                    )
                })}
                </Grid>
        </ClientsWrapper>
    )
   
}

export default FilmClients