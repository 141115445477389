import React from 'react'
import styled from 'styled-components'
import { ChevronDown, ChevronUp } from 'react-feather'

const Button = styled.button`
  font-family: 'Quasimoda', Arial, Helvetica, sans-serif;
  font-size: 1em;
  border-radius: 2px;
  color: var(--lipstick);
  border: 2px solid var(--lipstick);
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  transition: all 0.3s;
  padding: 14px 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1080px) {
    font-size: 1.21em;
    padding: 20px 40px;
  }
  &:focus,
  &:hover {
    cursor: pointer;
    border: 2px solid var(--black);
    color: var(--black);
    padding-left: 30px;
    padding-right: 50px;
  }

  svg {
    transform: translate(-30px, 0);
    position: absolute;
    opacity: 0;
    transition: all 0.3s;
    line-height: 2;
    align-self: flex-end;
  }

  &:focus svg,
  &:hover svg {
    opacity: 1;
    transform: translate(30px, 0);
    transition: all 0.3s;
  }
`

const ShowMoreButton = ({ children, onClick, clicked }) => {
  return (
    <Button onClick={onClick}>
      <div>{children}</div>
      {clicked && <ChevronUp />}
      {!clicked && <ChevronDown />}
    </Button>
  )
}

export default ShowMoreButton
