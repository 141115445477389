import React, { useState } from 'react'
import * as classes from './LoadingWrapper.module.css'
import styled from 'styled-components'
import { getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import ShowMoreButton from '../ui/ShowMoreButton'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  gap: 20px;
  max-width: 1080px;
  margin-left: 20px;
  margin-right: 20px;

  @media screen and (min-width: 780px) {
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 1080px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
  }
`

const ImageWrapper = styled.div`
  position: relative;

  &:hover > div {
    opacity: 0.8;
  }
`

const WorkItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
`

const Title = styled.h2`
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.03em;
  text-transform: uppercase;
`

const LoadingWrapper = (props) => {

    const loadMoreHandler = (event) => {
        event.preventDefault()
        setShowAll(showAll => !showAll)
    }

    const [showAll, setShowAll] = useState(false)

    return(
        <>
        <div className={!showAll ? classes.wrapper : classes.wrapperOpen}>
        <Grid>
          {props.data.allMdx.nodes.map((node) => {
            const image = getImage(node.frontmatter.featured_image)

            return (
              <WorkItem key={node.id}>
                <Title>{node.frontmatter.title}</Title>
                <ImageWrapper>
                  <Link to={node.slug}>
                    <GatsbyImage image={image} alt={node.frontmatter.title} />
                  </Link>
                </ImageWrapper>
              </WorkItem>
            )
          })}
        
        </Grid>
              
        </div>

        <ShowMoreButton onClick={loadMoreHandler} clicked={showAll}>{!showAll ? "Load More" : "Hide"}</ShowMoreButton>
        </>
    )
}

export default LoadingWrapper